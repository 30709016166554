
.news-grid {
	.c-grid {
		--grid-col-lg: 1fr 1fr 1fr;
		--grid-col-md: 1fr 1fr;
		--grid-col-sm: 1fr;
	}

	.pagination {
		display: flex;
		padding-top: 30px;
		margin: 0 auto;

		.page-numbers {
			padding: 0.5rem;
			font-weight: 900;
			color: $primary-color;
			text-decoration: none;
			font-size: var(--fluid-h5);
		}

		a.page-numbers {
			&:hover, &:active, &:focus {
				color: $primary-color-light;
			}

			&.active {
				color: $primary-color-dark;
			}
		}

		.current {
			color: $black;
			font-weight: 500;
		}
	}
}

/** News Card **/

.news-card {
	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
	max-height: 100%;
	text-decoration: none;
	border-radius: 20px;
	padding: 1rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

	.image-wrap {
		position: relative;
		aspect-ratio: 10 / 7.5;
		border-radius: 30px;
		overflow: hidden;
		margin-bottom: 1.5em;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}

	.content-wrap {
		display: flex;
		flex-flow: column nowrap;
		color: $dark-gray;
		flex-grow: 1;

		.title {
			color: $primary-color;
			font-family: $body-font-family;
			font-weight: 700;
			margin-bottom: .5rem;
		}

		.excerpt {
			margin-bottom: 1rem;
		}
		.read-more {
			margin-top: auto;
			position: relative;
			color: $secondary-color;
			font-weight: 900;
			padding-bottom: .5em;
			align-self: flex-end;

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 3px;
				background-color: $secondary-color;
				bottom: 0;
				left: 0;
			}
		}
	}
}
