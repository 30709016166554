[data-better-menu] {
	// Better Menu a Variables
	--bm-a-pad-top: 0.4rem;
	--bm-a-pad-left: 0.5rem;
	--bm-a-pad-right: 0.5rem;
	--bm-a-pad-bottom: 0.4rem;
	--bm-a-color: #{#6E6E6E};
	--bm-a-color-hover: #{$primary-color};
	--bm-a-color-active: #{$primary-color-light};
	--bm-a-transition: all 0.3s ease;

	// Submenus
	--bm-submenu-width: 225px;
	--bm-submenu-text-alignment: left;
	--bm-submenu-item-alignment: flex-start;
	// Vertical menu
	--bm-vert-submenu-pad: .4rem;

	// Dropdown arrow
	--bm-dropdown-arrow-height: 10px;
	--bm-dropdown-arrow-width: 15px;
	--bm-dropdown-arrow-padding: 6px;
	--bm-dropdown-arrow-color: #{$primary-color};
}

[data-better-modal] {
	--bm-transition-duration: 0.4s;
	--bm-transition-function: ease;

	--bm-overlay-color: rgba(0, 0, 0, 0.4);
}

[data-bm-button] {
	--bm-button-padding-tb: 10px;
	--bm-button-padding-lr: 10px;
	--bm-button-background-color: transparent;
	--bm-button-border: unset;
	--bm-button-border-radius: 0;

	--bm-toggle-line-width: 35px;
	--bm-toggle-line-height: 4px;
	--bm-toggle-line-gap: 5px;
	--bm-toggle-line-border-radius: 10px;
	--bm-toggle-line-color: #{$black};
}
