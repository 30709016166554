/**
 * Buttons
 **/

.fancy-btn {
	position: relative;
	display: flex;
	padding: .55em 2em;
	font-weight: bold;
	border-radius: 100px;
	text-align: center;
	letter-spacing: 1px;
	background: $primary-color;
	color: white;
	transition: all .3s ease;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		transition: all .3s ease;
		border-radius: 100px;
		z-index: -1;
	}

	// hover states
	&:hover, &:focus, &:active {
		color: white;

		&:before {

			background-color: rgba($white, .22);
			height: 100%;
		}
	}

	// active state - click
	&:active {
		color: white;
		&:before {
			background-color: rgba($black, .1);
		}
	}
}


.wp-block-buttons {
	.wp-block-button__link {
		@extend .fancy-btn;
	}
}
