$white: #ffffff;
$black: #323332;
$dark-gray: #4E4E4E;
$gray: #E7EDF4;

$light-gray: #F4F4F4;
$light-green: #E9F0EC;

$primary-color: #1059AF;
$secondary-color: #008838;
$tertiary-color: #9CB2A5;
$quaternary-color: $gray;
