.page-hero {
	position: relative;
	background-color: $gray;
	padding-top: 100px;
	padding-bottom: 100px;
	overflow: hidden;
	.hero-content {
		position: relative;
		max-width: 100%;
		z-index: 1;

		h1, h2, h3, h4 {
			max-width: 100%;
			width: 800px;
		}

		p {
			max-width: 100%;
			width: 400px;
		}
	}


	.go-back-link {
		position: relative;
		display: flex;
		font-weight: bold;
		font-size: var(--fluid-h6);
		color: $secondary-color;
		text-decoration: none;
		top: -30px;
	}

	.background-wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, white 0%, rgba(255, 255, 255, .5) 40%, rgba(255, 255, 255, 0) 100%);
			opacity: 0.7;
		}

		img {
			box-shadow: inset 0 0 0 2000px rgba($black, 0.9);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			z-index: -1;
		}
	}

}
