service-hero {
	position: relative;
	//display: flex !important;
	min-height: calc(75vh - 100px);
	background-color: $black;
	padding-bottom: 0 !important;

	--swiper-navigation-color: #{$secondary-color};
	--swiper-navigation-size: 30px;

	.swiper-button-next, .swiper-button-prev {
		color: white;
		font-weight: bold;

		&:before {
			content: '';
			position: absolute;
			background-color: $secondary-color;
			top: -10px;
			left: -10px;
			width: calc(100% + 20px);
			height: calc(100% + 20px);
			z-index: -1;
		}
		background-color: $secondary-color;
		//padding: 5px;
	}

	@include mq(l) {
		min-height: calc(100vh - 100px);
	}
	.hero-wrap {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
		align-content: center;
		grid-column: span 3;
		min-width: 0;

		@include mq(l) {
			grid-column: 2 / span 1;
		}
	}

	.video-background-wrap {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: 0;


		.iframe-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			min-height: 100%;
			min-width: 100%;
			padding-bottom: 56.25%;
		}

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			min-height: 100%;
			min-width: 100%;
			transform: translate(-50%, -50%);
			pointer-events: none;

			@media(min-aspect-ratio: 16 / 9) {
				height: 56.25vw;
			}

			@media(max-aspect-ratio: 16 / 9) {
				width: 177.78vh;
			}
		}
	}

	.hero-services-wrap {
		grid-column: span 3;
		position: absolute;

		bottom: 0;
	}

	.swiper-wrapper {
		//align-content: stretch;
		//box-sizing: border-box !important;
		//align-items: stretch;
		width: max-content;

	}

	.swiper-slide {
		flex: 1;
		display: flex;
		flex-flow: column;
		height: auto;
		padding: 10px;
		box-sizing: border-box !important;


		.hero-service-card {
			flex-grow: 1;
			max-height: 100%;
			margin-bottom: 1rem;

			.service-card-list-wrapper {
				display: flex;
				flex-grow: 1;

				ul {

				}
			}
		}
	}

	.hero-content, .hero-services-wrap {
		z-index: 1;
	}

	.hero-services-wrap {
		grid-column: span 3;
		position: absolute;

		bottom: 0;
		max-width: 100%;
		width: 100%;
		transform: translateY(47%);


		max-width: var(--global-width);

		@include mq(l) {
			grid-column: 2 / span 1;
		}
	}

	.hero-content {
		text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		///margin-top: auto;
		color: white;
		h1, h2, h3, h4 {
			color: white;
		}
		text-align: center;
	}
}

.hero-service-card {
	display: flex;
	flex-direction: column;
	//padding: 10px;
	filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.4));
	text-decoration: none;
	.service-card-header {
		display: flex;
		flex-flow: column;
		justify-items: center;
		align-items: center;
		text-align: center;
		background-color: $primary-color;
		margin-top: 80px;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
	    padding-bottom: 1em;
		//min-height: fit-content;
		min-height: calc(45% - 80px);
		flex-shrink: 0;


		.service-title {
			color: white;
			font-weight: bold;
			margin-top: 10px;
			font-family: $body-font-family;
		}

		.image-wrap {
			position: relative;
			aspect-ratio: 1 / 1;
			width: 60%;
			border-radius: 1000px;
			border: 5px solid $primary-color;
			margin-top: -40px;
			overflow: hidden;
			background: white;
			flex-shrink: 0;
			z-index: 0;
			transform: translateZ(0);

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				transition: all .3s ease;
				//border-radius: 1000px;
			}
		}
	}


	.service-card-list-wrapper {
		background: white;
		padding: 20px;
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
	}

	.service-list {
		margin-top: .5em;
		margin-left: unset;
		padding-left: unset;
		li {
			list-style: none;
		}

		.service-item, .service-item a {
			font-weight: 700;
			margin-bottom: .5rem;
		}
	}

	&:hover, &:focus, &:active {
		.image-wrap {
			img {
				filter: opacity(0.7);
				transform: scale(1.1);
				transform-origin: top left;
			}
		}
	}
}
