.fluentform {
	.ff-el-input--label {
		label {
			font-size: var(--fluid-body-sm);
			font-weight: 700 !important;
			color: $dark-gray;
		}
	}

	.ff-el-input--content {
		font-weight: 500 !important;

		input, textarea {
			font-size: var(--fluid-body);
			font-family: $body-font-family;
			border-radius: 0;
		}
	}


	.ff-el-group {
		button {
			@extend .fancy-btn;
			background-color: $secondary-color !important;
		}
	}

	.ff-message-success {
		background-color: white;
		border-radius: 15px;
		border: unset;
	}
}
