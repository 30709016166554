.service-icon-cards {
	.c-grid {
		--grid-col-lg: 1fr 1fr;
		--grid-col-md: 1fr 1fr;
		--grid-col-sm: 1fr;
		--grid-gap: 40px;

		@include mq(l) {

			--grid-gap: 40px;
		}
	}

	.service-single-card {
		background-color: $white;
		border-radius: 20px;
		padding: 20px;
		padding-top: 50px;
		box-shadow: 3px 3px 6px rgba(0, 0, 0, .15);
		margin-top: 30px;
		height: 100%;

		&:hover, &:active, &:focus {
			img {
				animation: wiggle 1s infinite;
				animation-timing-function: linear;
			}
		}

		@include mq(m) {
			padding: 30px;
			padding-top: 50px;
		}

		.service-card-header {
			position: relative;
			display: flex;
			align-items: flex-end;
			margin-top: -30px;
			//margin-top: -50px;
			margin-bottom: $padding;

			.image-wrap {
				position: relative;
				width: 110px;

				//width: auto;
				transform: translateZ(0);
				//background-color: $white;
				flex-shrink: 0;

				@include mq(m) {
				}

				.image-border-wrap {
					position: absolute;
					bottom: 0;
					border: 2px solid $primary-color;
					border-radius: 1000px;
					transform: translateZ(0);
					background-color: white;
					flex-shrink: 0;
					width: 100%;
					aspect-ratio: 1 / 1;
				}

				img {
					position: absolute;
					top: 15%;
					left: 15%;
					width: 70%;
					height: 70%;
					object-fit: contain;
					object-position: center	center;
				}
			}

			.title {
				color: $primary-color;
				font-weight: 500;
				font-size: var(--fluid-h5);
				margin-left: 1rem;
				margin-bottom: .5rem;

				@include mq(m) {
					margin-bottom: .5em;
				}

			}
		}

	}
}

//wiggle animation

@keyframes wiggle {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(4deg);
	}

	60% {
		transform: rotate(0deg);
	}

	75% {
		transform: rotate(-4deg);
	}

	100% {
		transform: rotate(0deg);
	}
}
