
testimonial-slider {
	position: relative;
	// Full height mods

	.swiper {
		z-index: 1;
		max-width: 100%;
		width: 800px;
		margin: 0 auto;
	}
	.swiper-wrapper {
		width: max-content;
	}

	.swiper-slide {
		flex: 1;
		display: flex;
		flex-flow: column;
		height: auto;
		padding: 10px;

	}

	.swiper-pagination {
		bottom: -5px;
	}

	.background-wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;

		svg {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.5;
			color: white;
			width: 100%;
			height: 100%;
		}
	}

	.testimonial-wrap {
		padding-top: 30px;
		padding-bottom: 30px;
		display: flex;
		flex-flow: column;
		flex-grow: 1;
		@include mq(m) {
			padding-left: 85px;
			padding-right: 85px;
			.quotation-left {
				position: absolute;
				top: 20px;
				left: 0;
			}

			.quotation-right {
				position: absolute;
				right: 0;
				bottom: 20px;
			}

		}

		.testimonial-name {
			margin-top: auto;
			align-self: flex-end;
			font-size: var(--fluid-h5);
			font-weight: 600;
			color: $primary-color;
		}
	}

	.quotation-left, .quotation-right {
		display: flex;
		width: 75px;
		font-weight: 500;
		font-family: $header-font-family;
		color: $tertiary-color;

		svg {
			width: 100%;
			height: 100%;
			fill: $tertiary-color;
		}
	}

	.quotation-left {
		align-self: flex-start;
		justify-self: flex-start;
	}

	.quotation-right {
		align-self: flex-end;
		justify-self: flex-end;
	}
}
