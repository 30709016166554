#footer {
	background: $light-gray;
	padding-bottom: 0;
	overflow: hidden;

	.footer-grid {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: flex-start;
		width: 290px;
		gap: 20px;
		max-width: 100%;
		justify-self: center;

		@include mq(m) {
			width: 100%;
			flex-flow: row wrap;
		}
	}

	a {
		color: $primary-color;
		text-decoration: none;
	}

	#footer-menu {
		ul:first-child {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: clamp(20px, 2.5vw, 60px);
			margin: 0 auto;

			> li {
				> a {
					display: flex;
					color: $primary-color;
					font-weight: bold;
					font-size: var(--fluid-h6);
					padding-top: 0;
					padding-bottom: 5px;
				}
			}
		}

		a {
			display: flex;
			padding: 2px 0;
			font-weight: bold;
			text-align: center;
			font-size: var(--fluid-body-sm);
			color: $dark-gray;

			&:hover, &:active, &:focus {
				color: $secondary-color;
			}

			&:active {
				color: $secondary-color;
			}
		}

		.callout-link {
			display: none;
		}
	}

	.logo-wrap {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		width: 100%;

		@include mq(l) {
			width: auto;
		}
		img {
			height: 80px;
		}
	}

	.soc-grid {
		margin-top: 1rem;
		margin-right: unset;
		margin-left: unset;

		@include mq(m) {
			margin-left: 20px;
			margin-right: auto;
		}

	}

	.footer-copyright {
		display: flex;
		gap: 10px;
		align-items: center;
		align-content: center;
		justify-content: center;
		text-align: center;
		align-self: center;
		margin-top: 30px;
		font-size: var(--fluid-body-sm);
		background: $primary-color;
		margin-bottom: 0;
		padding-bottom: 2px;
		grid-column: span 3;

		a {
			color: $white;
			font-weight: 700;
			margin: 0 10px;
			padding: 5px;
		}
	}

	.contact-info {
		ul {
			padding-left: unset;
			margin-left: unset;
			list-style: none;
			margin-top: 0;

			.contact-line {
				padding-left: unset;
				margin-bottom: 1em;
				a, div {
					display: flex;
					flex-flow: row nowrap;
					align-items: flex-start;
					align-self: flex-start;
					text-decoration: none;
					color: $primary-color;
					font-weight: bold;
					margin-bottom: 20px;

					figure {
						margin-top: unset;
						margin-left: unset;
						margin-bottom: unset;
						height: 1.3em;
						width: auto;
						margin-right: .5em;
						color: $secondary-color;

						svg {
							height: 100%;
						}
					}
				}

				a {
					&:hover, &:active, &:focus {
						color: $secondary-color;
					}
				}
			}
		}
	}
}

// abbreviate name so ad blockers don't hide it
.soc-grid {
	display: block;
	--icon-size: var(--fluid-h5);
	--icon-color: #{$tertiary-color};
	--icon-padding: 10px;
	//margin-left: calc(-1 * var(--icon-padding));
	//margin-right: calc(-1 * var(--icon-padding));
	a {
		text-decoration: none;
	}

	.soc-icon {
		padding: var(--icon-padding);

		svg {
			height: var(--icon-size);
			color: var(--icon-color);
			width: auto;
		}
	}
}
