.service-icon-list {
	margin-top: 0;

	.icon-grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		align-items: flex-end;
		gap: 30px;

		@include mq(m) {
			justify-content: space-between;
		}


		.service-icon-wrap {
			display: flex;
			flex-flow: column;
			align-items: center;
			margin: 0;

			img {
				height: 60px;
				width: 70px;
				object-fit: contain;
				object-position: center bottom;

				@include mq(m) {
					height: 65px;
					width: 75px;
				}
			}

			figcaption {
				color: $primary-color;
				font-weight: 700;
				margin-top: 1em;
				text-align: center;
				width: 110px;
				font-size: var(--fluid-body-sm);

				@include mq(m) {
					width: 120px;
				}
			}
		}
	}
}
