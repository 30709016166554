.service-square-grid {
	display: flex;
	flex-flow: column nowrap;
	margin: var(--padding) 0;

	.c-grid {
		--grid-col-lg: 1fr 1fr 1fr;
		--grid-col-md: 1fr 1fr;
		--grid-col-sm: 1fr 1fr;
		--grid-gap: 10px;
		width: auto;
		max-width: 100%;
		flex-grow: 1;
		align-self: stretch;
		margin: 0 auto;

		@include mq(m) {
			width: 650px;
		}

		@include mq(l) {
			width: 850px;
		}

		@include mq(xl) {
			width: 900px;
		}
	}

	.service-item-square {
		position: relative;
		aspect-ratio: 1 / 1;
		border-radius: 30px;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		justify-items: center;
		overflow: hidden;

		figure {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			overflow: hidden;
			padding: 0;
			margin: 0;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($black, .3);
				background-mix-blend-mode: multiply;
			}


			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.item-title {
			color: white;
			font-weight: bold;
			font-size: var(--fluid-body);
			z-index: 1;
			text-align: center;
			padding: 10px;

			@include mq(m) {
				padding: 30px;
				font-size: calc(var(--fluid-h5) - 10%);
			}
		}

	}
}
