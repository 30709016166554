

@import "page-hero/page-hero-styles";

@import "fancy-fifty-fifty/fancy-fifty-fifty-styles";
@import "service-hero/service-hero-styles";
@import "service-square-grid/service-square-grid-styles";
@import "service-icon-list/service-icon-list-styles";
@import "service-icon-cards/service-icon-cards-styles";
@import "news-slider/news-slider-styles";
@import "news-grid/news-grid-styles";
@import "testimonial-slider/testimonial-slider-styles";

.wp-block-image {
	figcaption {
		text-align: center;
	}
}

.wp-block-cover {
	justify-items: center;
	align-content: center;

	.wp-block-cover__inner-container {
		display: flex;
		flex-flow: column nowrap;
	}

	&.alignfull{
		height: auto;
		.wp-block-cover__image-background {
			grid-column: start / end;
			width: calc(100% + (var(--padding-horizontal) * 4));
			left: calc(-1 * var(--padding-horizontal));
		}

	}
	&[data-align="full"] {
		img {
			width: calc(100% + (var(--padding-horizontal) * 4));

		}
	}


}

[data-align="full"] {
	.wp-block-cover {
		img {
			grid-column: span 3;
		}
	}
}

.circle-image {
	position: relative;
	display: flex !important;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	aspect-ratio: 1 / 1;
	margin-bottom: 2.2em;
	border: 3px solid $primary-color;
	border-radius: 1000px;

	figcaption {
		position: absolute;
		top: 100%;
		padding-top: 20px;
		font-size: var(--fluid-body);
		color: var(--wp--preset--color--primary);
		text-align: center;

		@include mq(m) {
			font-size: var(--fluid-h6)
		}
	}

	img {
		position: absolute;
		top: 20%;
		left: 20%;
		width: 60% !important;
		height: 60% !important;

		border-radius: unset !important;

		object-fit: contain;
		object-position: center center;
	}

	.components-resizable-box__container {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;

		border-radius: unset !important;
	}

	&.full-image {
		overflow: hidden;
		transform: translateZ(0);

		img {
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;
			object-position: center center;
		}
	}
}

.remove-block-gap {
	margin-top: calc(-1 * var(--wp--style--block-gap));
	margin-bottom: calc(-1 * var(--wp--style--block-gap));
}


.gutenberg-content {
	.remove-block-gap:last-child {
		margin-bottom: 0;
	}
}

.advanced-dentistry {
	.ghostkit-grid {
		max-width: 350px;
		width: 100%;
		margin: 0 auto;
		@include mq(m) {
			max-width: 450px;
		}

		@include mq(l) {
			max-width: unset;
		}
	}
}

.staff-block {
	padding-top: 250px !important;
	padding-bottom: 30px !important;

	@include mq(m) {
		padding-top: 150px !important;
		padding-bottom: 150px !important;
	}

}

.post-type-post {
	.editor-styles-wrapper {
		.block-editor-block-list__layout {
			&.is-root-container > * {
				max-width: 800px;
			}
		}

		.edit-post-visual-editor__post-title-wrapper > * {
			max-width: 800px;
		}
	}
}
