.fancy-fifty-fifty {

	.c-grid {
		--grid-col-md: 1fr 2fr;
		--grid-col-lg: 1fr 1fr;
		--grid-gap: calc(var(--padding) * 2);

		@include mq(m) {
			--grid-gap: calc(var(--padding) * 4);
		}
	}

	.image-cell {
		display: flex;
		.image-wrap {
			position: relative;
			margin: 0 auto;
			width: 100%;
			max-width: 375px;
			aspect-ratio: 1 / 1;

			@include mq(m) {
				max-width: 100%;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				border-radius: 20px;
			}
		}
	}

	.info-cell {
		display: flex;
		flex-direction: column;
		margin: auto 0;

		*:nth-child(2) {
			order: 0;
		}
		.swoop-wrap {
			display: flex;
			order: 1;
			svg {
				position: relative;
				left: -10%;
				max-width: 65%;
				width: 100%;
				height: auto;
				@include mq(m) {
					max-width: 45%;
					left:  0;
				}
			}
		}

		> * {
			order: 2;
		}
	}

	&.mobile-below {
		@include mq(m, down) {
			.image-cell {
				order: 2;
			}

			.info-cell {
				order: 1;
			}
		}
	}

	&.mobile-above {
		@include mq(m, down) {
			.image-cell {
				order: 1;
			}

			.info-cell {
				order: 2;
			}
		}
	}

	&.desktop-left {
		@include mq(m up) {
			.image-cell {
				order: 1;
			}

			.info-cell {
				order: 2;
			}
		}
	}

	&.desktop-right {
		@include mq(m) {
			.swoop-wrap {
				justify-content: flex-end;
				svg {
					right: -10%;
					max-width: 65%;
					@include mq(m) {
						left: unset;
						max-width: 45%;
						right: 0;
					}
				}
			}
			.image-cell {
				order: 2;
			}

			.info-cell {
				order: 1;
			}
		}
	}
}
