.header-nav {
	@extend .s-section;
	position: sticky;
	top: 0;
	background: white;

	padding-top: unset;
	padding-bottom: 0;
	z-index: 100;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

	.header-row {
		display: flex;
		width: 100%;
	}

	.header-cell {
		display: flex;
	}

	.nav-cell {
		align-items: center;
		justify-content: flex-end;
		flex-grow: 1;
	}

	.logo-container {
		flex-shrink: 0;
		max-width: 100%;
	}

	.logo {
		@include mq(m) {
			height: 60px;
		}
		flex-shrink: 0;
	}

	a {
		font-weight: 900;
		letter-spacing: 1px;
		font-size: var(--fluid-body-xs);
	}

	.soc-grid {
		display: none;

		@include mq(l) {
			display: flex;
		}
	}


	.info-row {
		background-color: $light-green;
		padding: 5px var(--padding);
		.info-wrap {
			display: flex;
			justify-content: space-between;
			max-width: var(--global-width);
			margin: 0 auto;
			width: 100%;
			.contact-line {
				//display: flex;
				flex-flow: row nowrap;
				align-items: center;
				align-self: center;
				justify-content: center;
				text-decoration: none;
				color: #6E6E6E;
				font-weight: 900;
				font-size: var(--fluid-body-xs);
				display: none;

				@include mq(l) {
					display: flex;
				}


				figure {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: unset;
					margin-left: unset;
					margin-bottom: unset;
					margin-right: .4em;
					height: 1.2em;
					width: auto;
					color: $secondary-color;
					padding: 0;
					svg {
						height: 100%;
					}
				}


				&a {
					color: $dark-gray;
					&:hover, &:active, &:focus {
						color: $secondary-color;
					}
				}

				.forced-gray {
					color: $dark-gray !important;

				}

				&.green-link {
					color: $secondary-color;

					&:hover, &:active, &:focus {
						color: $secondary-color-dark;
					}
				}

				&.blue-link {
					color: $primary-color;

					&:hover, &:active, &:focus {
						color: $primary-color-dark;
					}
				}

				&.phone-link {
					display: flex;

					.forced-gray {
						display: none;

						@include mq(l) {
							display: flex;
						}
					}

				}
			}
		}
	}

}

// Better menu mods

#desktop-nav {
	display: none;
	align-self: center;

	> div {
		width: 100%;
		display: flex;
		align-self: center;

		> ul {
			justify-content: space-evenly;
			flex-grow: 1;
		}
	}


	@include mq(l) {
		display: flex;
	}

	a {
		margin: 0 20px;
		font-size: var(--fluid-body);
	}

	.callout-link {

		a {
			@extend .fancy-btn;
			background: $secondary-color;
			font-weight: 900;
			text-transform: unset;
			padding-left: 1em;
			padding-right: 1em;
			padding-top: 0.8em;
			padding-bottom: 0.8em;
			letter-spacing: 1px;
			border-radius: 100px;
			font-size: var(--fluid-body-sm);
		}

	}
	[data-bm-dropdown-arrow] {
		padding-left: 0.2em;
	}
}

#mobile-nav-button {
	display: flex;

	@include mq(l) {
		display: none;
	}
}

#navigation-modal {
	.contact-info {
		width: max-content;
		margin: 0 auto;
		ul {
			display: flex;
			flex-flow: column nowrap;
			padding-left: unset;
			list-style: none;
			margin: 0 0;

			.contact-line {
				padding-left: unset;
				margin-bottom: 1.5em;
				margin-right: auto;
				margin-left: unset;
				align-self: flex-start;
				display: block;

				&:last-child {
					margin-bottom: 0;
				}
				a, div {
					display: flex;
					flex-flow: row nowrap;
					//align-items: flex-start;
					//align-self: flex-start;
					text-decoration: none;
					color: $white;
					font-weight: bold;
					margin-bottom: 0;

					figure {
						margin-top: unset;
						margin-left: unset;
						margin-bottom: unset;
						height: 1.3em;
						width: auto;
						margin-right: .5em;
						color: $secondary-color;

						svg {
							height: 100%;
						}
					}
				}

				ul {
					display: flex;
					flex-flow: column nowrap;
					align-self: flex-start;

				}

				a {
					&:hover, &:active, &:focus {
						color: $secondary-color;
					}
				}
			}
		}
	}

	a {
		color: white;

		&:hover,
		&:focus {
			color: $secondary-color;
		}

		&:active {
			color: $secondary-color-dark;
		}
	}

	[data-bm-card] {
		width: 100%;
		background: $primary-color;

		.bm-card-inner {
			padding: 2em;
		}

		[data-bm-button] {
			color: white;
			margin-left: auto;
			padding: 1rem;
			font-weight: 500;
			cursor: pointer;

			svg {
				padding: 0.4rem 0.2rem;
			}
		}
	}

	.nav-donate-btn {
		display: flex;
		align-items: center;
		margin-top: 2rem;

		svg {
			height: 1em;
			margin-right: 0.4rem;
		}
	}
}

// make sub menu items abit less bold

#mobile-nav {
	flex: 1;
	align-self: center;
	margin: 0 auto;

	ul {
		height: 100%;
	}


	li {
		margin-bottom: .8em;

		a {
			align-self: center;
			font-size: var(--fluid-h6);


		}

		> ul {
			padding-top: 0;

			a {
				font-size: calc(var(--fluid-body-sm) * 1.2);
				font-weight: 900;
				padding-top: .4em;
				padding-bottoM: .4em;
				padding-left: 1em;

			}

		}
	}

	.callout-link {
		margin-top: auto;
		margin-bottom: 40px;

		a {
			@extend .fancy-btn;
			background-color: $secondary-color;
			font-size: var(--fluid-body);
			color: white !important;
		}
	}
}


.menu-row {
	padding-top: 0.8em;
	padding-bottom: 0.8em;
}


#navigation-modal {
	[data-bm-card] {
		height: 100%;

		@include mq(m) {
			width: 350px;
		}
	}

	[data-bm-close] {

	}

	.card-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 20px;
	}

	[data-bm-card] {
		display: flex;
		flex-flow: column nowrap;
	}

	.bm-card-inner {
		display: flex;
		flex-flow: column nowrap;
		padding-top: 100px;
		flex-grow: 1;
	}

	a {
		font-size: var(--fluid-body);
		//text-align: center;
		--bm-dropdown-arrow-color: #{$secondary-color};
	}

	.logo-container {
		margin-top: 2rem;
		//display: flex;
		display: none;

		img {
			height: 100%;
			margin: 0 auto;
			filter: drop-shadow(1px 1px 4px rgba($black, .2));
			align-self: stretch;
		}

	}

	.soc-grid {
		margin-top: auto;
		margin-left: auto;
		margin-right: auto;
		--icon-color: white;
		display: flex;
		align-self: flex-end;
		align-items: center;
		align-content: center;
		justify-content: center;
	}
}

.bm-card-inner {
	max-width: 100%;
	margin: 0 auto;
	width: 375px;
}
