
news-slider {
	// Todo the section styles are too "important"
	grid-column: start / end !important;
	justify-self: center;
	width: 100%;

	@include mq(l) {
		// same here
		grid-column: content / span 1 !important;
	}

	// Full height mods
	.swiper-wrapper {
		width: max-content;
	}

	.swiper-slide {
		flex: 1;
		display: flex;
		flex-flow: column;
		height: auto;
		padding: 10px;

	}
}


/** News Card **/

.news-card {
	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
	max-height: 100%;
	text-decoration: none;
	border-radius: 20px;
	padding: 1rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

	.image-wrap {
		position: relative;
		aspect-ratio: 10 / 7.5;
		border-radius: 30px;
		overflow: hidden;
		margin-bottom: 1.5em;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}

	.content-wrap {
		display: flex;
		flex-flow: column nowrap;
		color: $dark-gray;
		flex-grow: 1;

		.title {
			color: $primary-color;
			font-family: $body-font-family;
			font-weight: 700;
			margin-bottom: .5rem;
		}

		.excerpt {
			margin-bottom: 1rem;
		}
		.read-more {
			margin-top: auto;
			position: relative;
			color: $secondary-color;
			font-weight: 900;
			padding-bottom: .5em;
			align-self: flex-end;

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 3px;
				background-color: $secondary-color;
				bottom: 0;
				left: 0;
			}
		}
	}
}
