.body-controller {
	position: relative;
	overflow-x: hidden;
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}
}


body {
	display: flex;
	flex-flow: column nowrap;
	margin: 0;

	&:not([data-s-loaded]) {
		* {
			transition: none !important;
		}
	}
}
